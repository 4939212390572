$(window).load(function(){

    if (window.matchMedia('screen and (min-width:1025px)').matches) {
        //PCなら
        $('.dl_default').each(function(){
            $(this).find('dt,dd').not(".dl_default dl dt , .dl_default dl dd").tile(2);
        });
        $('.works-list').each(function(){
            $(this).find('li a').tile(3);
        });
    } else {
        //SPなら
        $('.works-list').each(function(){
            $(this).find('li a').tile(2);
        });
    }

});
$(function(){

    var body = $('body');

    //SVGスプライト
    $.ajax({
        type: 'get',
        url: '/assets/svg/icons.svg'//ここにパスを入れる（環境に合わせて変更）
    }).done(function(data) {
        var svg = $(data).find('svg');
        body.prepend(svg);
    });

    if (window.matchMedia('screen and (min-width:1025px)').matches) {
        //PCなら
    } else {
        //SPなら
        //menu
        $(".gnav_btn").click(function() {
            $(this).toggleClass("close");
            $(".gnav_box").toggleClass("open");
            return false;
        });
    }

    /*ページトップ指定*/
    var pagetop = $('.pagetop');
    pagetop.css('bottom', '-200px');

    /*スクロールしたら*/
    $(window).scroll(function () {
        if ($(this).scrollTop() > 300) {
            pagetop.stop().animate({'bottom' : '20px'}, 200);
        } else {
            pagetop.stop().animate({'bottom' : '-200px'}, 200);
        }
    });
    
    /* ページトップアクション */
    pagetop.click(function () {
        $('body, html').animate({ scrollTop: 0 }, 500);
        return false;
    });

    //アコーディオン
    $(".accordion dd").hide();
    $(".accordion dd").hide();
    $('.accordion dt').on('click', function() {
        if($(this).next().is(':visible')) { //既に開いている場所なら
            $(this).next().slideUp(function(){
                var p = $(this).offset().top;
                $('html,body').animate({ scrollTop: p - 50 }, '300');
                return false;
            }); //閉じる
            $(this).removeClass('active'); //.activeを削除
        } else { //閉じている場所なら
            $('.accordion dd').slideUp(); //全部閉じる
            $('.accordion dd').removeClass('active'); //.active全削除
            $(this).next().slideDown(function(){
                var p = $(this).offset().top;
                $('html,body').animate({ scrollTop: p - 50 }, '300');
                return false;
            }); //開く
            $(this).addClass('active'); //.active付加
        }
    });


    //ライトボックス
    var layer = $('#js_layer');
    $('.modalBtn').on('click', function(e) {
        $(this).prev('.popup').fadeIn('slow');
        $(layer).addClass('on');
        e.preventDefault();
    });
    $('.closeBtn').on('click', function(e) {
        $('.popup').hide();
        $(layer).removeClass('on');
        e.preventDefault();
    });
    $(layer).on('click' , function(e) {
        $('.popup').hide();
        $(layer).removeClass('on');
        e.preventDefault();
    });

});

/*--------------------------------------------------
スマートフォン電話対応
--------------------------------------------------*/
$(function() {
    if (!isPhone())
        return;

    $('a[data-action=call]').each(function() {
        var $ele = $(this);
        var $tel = $(this).data('tel');
        $ele.attr('href', 'tel:' + $tel);
    });
});

function isPhone() {
    return (navigator.userAgent.indexOf('iPhone') > 0 || navigator.userAgent.indexOf('Android') > 0);
}